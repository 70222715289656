import React, { FC } from 'react';
import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import styled from 'styled-components';

interface DescriptionBlockProps {
  data: {
    title: string;
    description: string;
    image: ImageDataValue;
  };
}

const DescriptionBlock: FC<DescriptionBlockProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <ImageWrapper>
          <Image localFile={data.image?.localFile} alt={data.title} />
        </ImageWrapper>
        <Content>
          <Title>{data.title}</Title>
          <Description>{data.description}</Description>
        </Content>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 668px;
  overflow: hidden;
  border-radius: 15px;
  margin-right: 60px;
  @media (max-width: 1200px) {
    margin-right: 30px;
  }
  @media (max-width: 1024px) {
    max-width: none;
    margin-right: 0;
    align-self: center;
  }
`;

const Content = styled.div`
  max-width: 500px;
  @media (max-width: 1024px) {
    max-width: none;
    margin-bottom: 32px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title3SemiBold32};
  color: ${COLORS.black1};
  margin-bottom: 40px;
  @media (max-width: 500px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    color: ${COLORS.black2};
    margin-bottom: 16px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.body2Light16};
  color: ${COLORS.black1};
`;

export default DescriptionBlock;
