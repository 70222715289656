import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { LocaleContext } from '@/contexts/LocaleContext';
import { ProductLocaleIdContext } from '@/contexts/ProductLocaleIdContext';
import { LocalizationData } from '@/types';
import '@/libs/localizations';
import dayjs from 'dayjs';
import { ClientContext, GraphQLClient } from 'graphql-hooks';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { API_URLS } from '@/constants';
import { WhatsappLink } from '@/components/WhatsappLink';

interface ProductLayoutProps {
  localizations: LocalizationData[];
  locale?: string;
}

const config = new GraphQLClient({
  url: API_URLS.apiStrapiUrl,
});

const ProductLayout: FC<ProductLayoutProps> = ({
  localizations,
  locale = 'en',
  children,
}) => {
  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);
  return (
    <ClientContext.Provider value={config}>
      <LocaleContext.Provider value={locale}>
        <ProductLocaleIdContext.Provider value={localizations}>
          <Root>
            <Header />
            <WhatsappLink />
            {children}
            <Footer context={ProductLocaleIdContext} />
          </Root>
        </ProductLocaleIdContext.Provider>
      </LocaleContext.Provider>
    </ClientContext.Provider>
  );
};

const Root = styled.div`
  position: relative;
`;

export default ProductLayout;
