import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Feature {
  id: number;
  title: string;
  icon: ImageDataValue;
}

interface FeaturesProps {
  features: Feature[];
  title: string;
  subtitle: string;
}

const Features: FC<FeaturesProps> = ({ features, title, subtitle }) => {
  return (
    <section>
      <StyledContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <FeaturesList>
          {features.map((item) => (
            <FeaturesItem key={item.id}>
              <FeaturesItemIconWrap>
                <Image localFile={item.icon?.localFile} alt={item.title} />
              </FeaturesItemIconWrap>
              <FeaturesItemTitle>{item.title}</FeaturesItemTitle>
            </FeaturesItem>
          ))}
        </FeaturesList>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }
  @media (max-width: 500px) {
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 500px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 16px;
    text-align: left;
  }
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.gray};
  margin-bottom: 80px;
  @media (max-width: 500px) {
    margin-bottom: 32px;
    text-align: left;
  }
`;

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  gap: 80px;
  width: 100%;
  @media (max-width: 1100px) {
    gap: 50px;
  }
  @media (max-width: 500px) {
    gap: 60px 17px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`;

const FeaturesItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  max-width: 185px;
`;

const FeaturesItemIconWrap = styled.div`
  max-width: 70px;
  margin-bottom: 45px;
  @media (max-width: 500px) {
    margin-bottom: 24px;
  }
`;

const FeaturesItemTitle = styled.p`
  ${TYPOGRAPHY.body1Medium18};
  color: ${COLORS.black1};
  text-align: center;
`;

export default Features;
