import { Accordion } from '@/components/Accordion';
import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { AccordionItemData } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface SpecificationsProps {
  data: {
    title: string;
    subtitle: string;
    specificationsList: AccordionItemData[];
  };
}

const Specifications: FC<SpecificationsProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
        <StyledAccordion data={data.specificationsList} />
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 30px;
  @media (max-width: 1024px) {
    padding-top: 50px;
  }
  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 650px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    color: ${COLORS.black2};
    margin-bottom: 16px;
    text-align: left;
  }
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.gray};
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: 650px) {
    margin-bottom: 36px;
    text-align: left;
  }
`;

const StyledAccordion = styled(Accordion)`
  max-width: 1016px;
`;

export default Specifications;
