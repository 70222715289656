import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { DownloadBlockData } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface DownloadsBlockProps {
  data: {
    title: string;
    downloadLinks: DownloadBlockData[];
  };
}

const DownloadsBlock: FC<DownloadsBlockProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <Title>{data.title}</Title>
        <DownloadsList>
          {data.downloadLinks.map((item) =>
            item.file ? (
              <DownloadsItem key={item.id}>
                <IconWrap>
                  <Image localFile={item.icon?.localFile} alt={item.title} />
                </IconWrap>
                <DownloadsItemTitle>{item.title}</DownloadsItemTitle>
                <DownloadsItemLink
                  href={item.file?.localFile.publicURL}
                  download
                />
              </DownloadsItem>
            ) : null,
          )}
        </DownloadsList>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black1};
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 650px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    color: ${COLORS.black2};
    margin-bottom: 35px;
    text-align: left;
  }
`;

const DownloadsList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1016px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DownloadsItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.lightGray4};
  border-radius: 20px;
  padding: 20px;
  position: relative;
  width: 100%;
  margin-right: 24px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const IconWrap = styled.div`
  max-width: 40px;
  margin-right: 20px;
`;

const DownloadsItemTitle = styled.p`
  ${TYPOGRAPHY.headlineMedium22};
  color: ${COLORS.black1};
`;

const DownloadsItemLink = styled.a`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export default DownloadsBlock;
