import React from 'react';
import { Seo } from '@/components/Seo';
import { Banner } from '@/pageComponents/ProductPage/Banner';
import { Features } from '@/pageComponents/ProductPage/Features';
import { DescriptionBlock } from '@/pageComponents/ProductPage/DescriptionBlock';
import { MainHeading } from '@/components/MainHeading';
import { ContactForm } from '@/pageComponents/BusinessSolution/ContactForm';
import { Specifications } from '@/pageComponents/ProductPage/Specifications';
import { DownloadsBlock } from '@/pageComponents/ProductPage/DownloadsBlock';
import ProductLayout from '@/layouts/ProductLayout';

const ProductTemplate = ({ pageContext }) => {
  const {
    locale,
    seo,
    hero,
    product_features,
    features,
    description,
    contactUs,
    specificationsBlock,
    downloadsBlock,
    localizations,
  } = pageContext.product;

  return (
    <ProductLayout localizations={localizations} locale={locale}>
      <Seo data={seo} localizations={localizations} />
      <MainHeading text={seo.mainHeading} />
      <Banner data={hero} />
      <Features
        features={product_features}
        title={features.title}
        subtitle={features.subtitle}
      />
      <DescriptionBlock data={description} />
      <Specifications data={specificationsBlock} />
      <DownloadsBlock data={downloadsBlock} />
      <ContactForm sectionData={contactUs} />
    </ProductLayout>
  );
};

export default ProductTemplate;
