import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Image } from '@/components/Image';

interface BannerProps {
  data: {
    title: string;
    subtitle: string;
    mainCharacters: string;
    image: ImageDataValue;
  };
}

const Banner: FC<BannerProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <DecorLine />
        <Content>
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
          <Characters>
            <ReactMarkdown>{data.mainCharacters}</ReactMarkdown>
          </Characters>
        </Content>
        <ImageWrapper>
          <Image localFile={data.image?.localFile} alt={data.title} />
        </ImageWrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayDarker3};
  margin-top: -86px;
  height: 100vh;

  @media (max-width: 1024px) {
    height: auto;
  }
  @media (max-width: 500px) {
    margin-top: -117px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 190px;
  padding-bottom: 140px;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  max-width: none;
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 50px;
    padding-left: 32px;
    padding-right: 32px;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    padding-top: 140px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Content = styled.div`
  max-width: 42.01vw;
  margin-bottom: 2.77vw;
  @media (max-width: 1300px) {
    max-width: 605px;
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  margin-bottom: 20px;
  color: ${COLORS.black1};
  @media (min-width: 1920px) {
    font-size: 75px;
    line-height: 110px;
    margin-bottom: 30px;
  }
  @media (max-width: 1300px) {
    ${TYPOGRAPHY.title2SemiBold48};
    margin-bottom: 20px;
  }
  @media (max-width: 650px) {
    ${TYPOGRAPHY.title3SemiBold32};
    margin-bottom: 32px;
  }
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.headlineMedium22};
  margin-bottom: 40px;
  color: ${COLORS.grayDarker2};
  @media (min-width: 1920px) {
    ${TYPOGRAPHY.title4Medium28};
    margin-bottom: 40px;
  }
  @media (max-width: 1300px) {
    ${TYPOGRAPHY.headlineMedium22};
    margin-bottom: 40px;
  }
  @media (max-width: 650px) {
    margin-bottom: 20px;
  }
`;

const Characters = styled.div`
  li {
    ${TYPOGRAPHY.headlineMedium22};
    padding-left: 30px;
    margin-bottom: 40px;
    color: ${COLORS.black1};
    position: relative;
    :last-child {
      margin-bottom: 0;
    }
    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 3px;
      background-color: ${COLORS.purple};
    }
  }
  @media (min-width: 1920px) {
    li {
      ${TYPOGRAPHY.headlineMedium22};
      margin-bottom: 40px;
    }
  }
  @media (max-width: 1300px) {
    li {
      ${TYPOGRAPHY.body1Regular18};
      padding-left: 30px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 650px) {
    li {
      font-weight: 300;
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  right: 12.2%;
  bottom: 77px;

  max-width: 511px;
  @media (min-width: 1920px) {
    max-width: 30vw;
  }
  @media (max-width: 1300px) {
    right: 50px;
    max-width: 511px;
  }
  @media (max-width: 1200px) {
    right: 32px;
  }
  @media (max-width: 1024px) {
    position: static;
    align-self: center;
  }
  @media (max-width: 768px) {
    max-width: 220px;
  }
`;

const DecorLine = styled.div`
  width: 861px;
  height: 440px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url('/images/product-image-line.svg') no-repeat center;
  background-size: cover;
  @media (min-width: 1920px) {
    width: 48vw;
    height: 25vw;
  }
  @media (max-width: 1300px) {
    width: 861px;
    height: 440px;
    right: -15%;
  }
  @media (max-width: 1024px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    width: 375px;
    height: 195px;
  }
`;

export default Banner;
